.product__card__card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
}

.product__card__card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.product__card {
    display: flex;
    flex-direction: column;
    width: 250px;
}

.product__image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    height: 200px;
    background-color: #f7f7f7;
}

.product__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s;
}

.product__card__detail {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.product__name {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 8px;
}

.product__name a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
}

.product__name a:hover {
    color: #007bff;
}

.product__description {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 8px;
}

.product__price {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
}

.product__card__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product__card__action .MuiIconButton-root {
    border: 2px solid #eee;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
    transition: border-color 0.3s, background-color 0.3s;
}

.product__card__action .MuiIconButton-root:hover {
    border-color: #007bff;
    background-color: #f0f8ff;
}